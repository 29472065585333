// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils.ts"
);
import.meta.hot.lastModified = "1730486026893.4316";
}
// REMIX HMR END

import { useMatches } from "@remix-run/react";
import { useMemo } from "react";
import { User } from "./services/session.server.ts";

import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

import { isNull } from 'lodash'

// redis:6379

/**
 * This base hook is used in other hooks to quickly search for specific data
 * across all loader data using useMatches.
 * @param {string} id The route id
 * @returns {JSON|undefined} The router data or undefined if not found
 */
export function useMatchesData(
  id: string
): Record<string, unknown> | undefined {
  const matchingRoutes = useMatches();
  const route = useMemo(
    () => matchingRoutes.find((route) => route.id === id),
    [matchingRoutes, id]
  );
  return route?.data;
}

function isUser(user: any): user is User {
  return user && typeof user === "object" && typeof user.accessToken === "string";
}

export function useOptionalUser(): User | undefined {
  const data = useMatchesData("root");
  if (!data || !isUser(data.user)) {
    return undefined;
  }
  return data.user;
}

export function userHasRole(user, role: string) {
  return (user != null && user.roles.includes(role))
}

type UserPermission =
  'facility.list.any' |
  'facility.list.own' |
  'facility.add' |
  'facility.edit.any' |
  'facility.edit.own' |
  'facility.archive' |
  'patient.list.any' |
  'patient.list.own' |
  'patient.add' |
  'patient.edit' |
  'patient.edit.any' |
  'patient.edit.own' |
  'patient.archive' |
  'inbox.list.any' |
  'inbox.list.own' |
  'assessment.list.any' |
  'assessment.list.own' |
  'assessment.add' |
  'assessment.edit' |
  'assessment.edit.any' |
  'assessment.edit.own' |
  'assessment.void' |
  'physiciansReport.view'

export function userHasPermission(permission: UserPermission, context: any = null) {
  let user
  if (!isNull(context) && !isNull(context['user'])) {
    user = context['user']
  }
  else {
    user = useUser()
  }

  switch(permission) {
    case 'patient.add':
    case 'patient.edit':
    case 'physiciansReport.view':
    case 'assessment.add':
    case 'assessment.edit':
    case 'inbox.list.any':
      return !user.roles.includes('authenticated_read_only')

    default:
      return false
  }
}

export function useOptionalOrg(): any | undefined {
  const data = useMatchesData("root");
  if (!data || !data.org) {
    return undefined;
  }
  return data.org;
}

export function useUser(): User {
  const maybeUser = useOptionalUser();
  if (!maybeUser) {
    // This should never actually happen
    throw new Error(
      "No user found in root loader, but user is required by useUser. If user is optional, try useOptionalUser instead."
    );
  }
  return maybeUser;
}

// function useSessionTimeout(request: Request, auth: any) {
//     const submit = useSubmit();
//     const transition = useTransition();

//     useEffect(() => {
//         const timer = setTimeout(() => {
//             submit(null, { method: "get", action: "/logout" });
//         }, 5 * 60_000);

//         return () => clearTimeout(timer);
//     }, [submit, transition]);
// }

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}


export function flattenObject (obj:any, parentKey = "") {
  let result:any = {}

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = parentKey ? `${parentKey}[${key}]` : key
      const value = obj[key]

      if (typeof value === "object" && value !== null) {
        const flattened = flattenObject(value, newKey)
        result = { ...result, ...flattened }
      } else {
        result[newKey] = value
      }
    }
  }
  return result
}
